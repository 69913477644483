import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { json, redirect } from '@remix-run/cloudflare';
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
} from '@remix-run/react';
import { Templates, generateTopics, moderateContent } from 'leap-ai';
import { useEffect } from 'react';

import { Heading, Text, Button, Textarea, displayToaster } from 'aslan';
import { useT } from 'i18n';
import { onboardingCookie, readOnboardingCookie } from 'leap-auth';
import { fieldErrorMessage } from 'utils';

import Layouts from '~/components/Layouts';
import OnboardingImage from '~/components/OnboardingImage';
import { signUpUrl } from '~/helpers/routes';
import { useTurnstile } from '~/hooks/useTurnstile';
import { i18n } from '~/i18n.server';
import {
  failedTurnstileResponse,
  loadingTurnstileResponse,
  handleTurnstileValidation,
} from '~/services/turnstile';

import type {
  MetaFunction,
  LoaderFunctionArgs,
  ActionFunctionArgs,
} from '@remix-run/cloudflare';
import type { ExternalScriptsFunction } from 'remix-utils/external-scripts';
import type { ErrorType } from 'utils';

interface LoaderData {
  signUpUrl: string;
  niche: string;
  cloudflareTurntitleSiteKey: string | null;
}

interface ActionData {
  errors?: ErrorType[];
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return data?.meta ?? [];
};

export async function loader(args: LoaderFunctionArgs) {
  const t = await i18n.getFixedT(args.request);
  const cookie = await readOnboardingCookie(args);
  const cloudflareTurntitleSiteKey = args.context.env
    .CLOUDFLARE_TURNSTILE_ENABLED
    ? args.context.env.CLOUDFLARE_TURNSTILE_SITE_KEY
    : null;

  return {
    signUpUrl: signUpUrl(args),
    meta: [{ title: t('onboarding.expertise.meta.title') }],
    niche: cookie.niche as string,
    cloudflareTurntitleSiteKey,
  };
}

export async function action(args: ActionFunctionArgs) {
  const { request, context } = args;
  const t = await i18n.getFixedT(request);
  const body = await request.formData();
  const niche = body.get('niche')?.toString().trim();

  const { turnstileFailed, isTurnstileLoading } =
    await handleTurnstileValidation(body, args);

  if (isTurnstileLoading) {
    return loadingTurnstileResponse(args, t);
  }

  if (turnstileFailed) {
    return failedTurnstileResponse(args, t);
  }

  if (!niche) {
    return json({
      errors: [
        {
          field: 'niche',
          message: t('onboarding.expertise.errors.missingNiche'),
        },
      ],
    });
  }

  const moderationResults = await moderateContent(context, niche);
  if (moderationResults?.isFlagged) {
    return json({
      errors: [
        {
          field: 'niche',
          message: t('onboarding.expertise.errors.unsafeNiche'),
        },
      ],
    });
  }

  const debug =
    new URL(args.request.url).searchParams.get('debug') || undefined;
  const topicResult = await generateTopics(
    niche,
    context,
    Templates.GUIDE,
    debug
  );

  if (topicResult.error) {
    return json({
      errors: [
        {
          field: 'base',
          message: t(`onboarding.expertise.errors.${topicResult.error}`),
        },
      ],
    });
  }

  const cookie = await readOnboardingCookie(args);
  cookie.niche = niche;
  cookie.topics = topicResult.topics;
  cookie.template = 'guide';

  return redirect('/onboarding/topics', {
    headers: {
      'Set-Cookie': await onboardingCookie(args.context).serialize(cookie),
    },
  });
}

globalThis._turnstileCb = function () {
  console.log('Cloudflare Turnstile loaded');
};

const scripts: ExternalScriptsFunction = () => [
  {
    defer: true,
    src: 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=_turnstileCb',
  },
];

export const handle = { scripts };

function GenerateTopicsButton() {
  const navigation = useNavigation();
  const t = useT('translation', 'onboarding');
  const isSubmitting =
    navigation.state === 'submitting' || navigation.state === 'loading';

  return (
    <Button
      color="yellow"
      disabled={isSubmitting}
      type="submit"
      hasProgressState={isSubmitting}
      isFullWidth
      isRounded
    >
      {t(`expertise.submit.${navigation.state}`)}
      <ArrowLongRightIcon aria-hidden className="h-5 w-5 ml-2" />
    </Button>
  );
}

export default function Step1() {
  const t = useT('translation', 'onboarding');
  const data = useActionData<ActionData>();

  const { signUpUrl, niche, cloudflareTurntitleSiteKey } =
    useLoaderData<LoaderData>();
  const turnstileWidgetId = 'turnstileWidgetId';

  useTurnstile(turnstileWidgetId, cloudflareTurntitleSiteKey as string);

  useEffect(() => {
    if (!data) return;
    const baseError = fieldErrorMessage(data?.errors ?? [], 'base');
    if (!baseError) return;
    displayToaster({
      type: 'alert',
      message: baseError.message as string,
      options: { duration: Infinity },
    });
  }, [data]);

  return (
    <Layouts.Split>
      <Layouts.Split.LeftColumn>
        <Heading as="h1" size="2xl" className="sm:text-3xl mb-4 font-serif">
          {t('expertise.getStartedTitle')}
        </Heading>
        <Form
          method="post"
          id="onboarding-expertise"
          className="flex flex-col justify-center gap-y-4"
        >
          <Textarea
            name="niche"
            label={
              <Text size="sm" className="md:text-base mb-4">
                {t('expertise.expertCaption')}
              </Text>
            }
            error={fieldErrorMessage(data?.errors ?? [], 'niche')?.message}
            rows={3}
            required
            defaultValue={niche}
            maxLength={500}
          />
          <div id={turnstileWidgetId} />
          <GenerateTopicsButton />
          <a href={signUpUrl} className="mt-4 underline">
            {t('expertise.skipButton')}
          </a>
        </Form>
      </Layouts.Split.LeftColumn>
      <Layouts.Split.RightColumn>
        <OnboardingImage />
      </Layouts.Split.RightColumn>
    </Layouts.Split>
  );
}
