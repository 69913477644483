import { useEffect } from 'react';

// Define the useTurnstile hook
export function useTurnstile(
  turnstileWidgetId: string,
  cloudflareTurntitleSiteKey: string
): void {
  useEffect(() => {
    if (!cloudflareTurntitleSiteKey) {
      return;
    }

    // Define a variable to hold the widget ID
    let widgetId: string | null;

    const renderTurnstile = () => {
      if (window.turnstile) {
        // Render the turnstile widget and capture the widget ID
        widgetId = window.turnstile.render(`#${turnstileWidgetId}`, {
          sitekey: cloudflareTurntitleSiteKey,
          theme: 'light',
          size: 'normal',
        });
        return true; // Turnstile loaded and rendered
      } else {
        return false; // Turnstile not loaded
      }
    };

    // Check if turnstile is rendered successfully
    if (!renderTurnstile()) {
      let reloadCount = parseInt(
        localStorage.getItem('reloadCount') || '0',
        10
      );
      reloadCount += 1;

      if (reloadCount <= 3) {
        // Only reload a maximum of 3 times
        localStorage.setItem('reloadCount', reloadCount.toString());
        window.location.reload();
      } else {
        localStorage.removeItem('reloadCount'); // Reset the reloadCount for future attempts
      }
    } else {
      localStorage.removeItem('reloadCount'); // Reset the reloadCount if turnstile is rendered successfully
    }

    // Return a cleanup function to remove the turnstile widget
    return () => {
      if (window.turnstile && widgetId) {
        window.turnstile.remove(widgetId);
      }
    };
  }, [turnstileWidgetId, cloudflareTurntitleSiteKey]);
}
